import * as React from "react";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import "../styles/impressum.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const ImpressumPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LayoutComponent page={Page.IMPRINT} seo={{ title: t("imprint.title") }}>
      <div className="padding-wrapper-60">
        <h2 className="main-headline">
          {t("imprint.title")}
          <span />
        </h2>
        <div
          className="text-wrapper--paragraph bold"
          dangerouslySetInnerHTML={{
            __html: t("imprint.mainLocation.title"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold"
          dangerouslySetInnerHTML={{
            __html: t("imprint.mainLocation.name"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("imprint.mainLocation.address"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("imprint.secondLocation.title"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("imprint.secondLocation.address"),
          }}
        />
        <div
          className="margin-top"
          dangerouslySetInnerHTML={{
            __html: t("imprint.representative"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.contact.phone"),
          }}
        />
        <div
          className="link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contact.web"),
          }}
        />
        <div
          className="link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contact.mail"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.vat"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.textOne"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.textTwo"),
          }}
        />
        <div
          className="margin-top"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.title"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.name"),
          }}
        />
        <div
          className="margin-top"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.subTitle"),
          }}
        />
        <div
          className="link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.info"),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.since"),
          }}
        />
        <div
          className="link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("imprint.contentRepresentive.more"),
          }}
        />
      </div>
    </LayoutComponent>
  );
};

export default ImpressumPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
